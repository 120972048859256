import { Center, SimpleGrid, Text, SimpleGridProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export type NavigateButtonProps = {
	label: string
	to: string
}

export const NavigateButton = ({ label, to }: NavigateButtonProps) => {
	return (
		<Center
			justifyContent="space-between"
			as={Link}
			to={to}
			w="100%"
			border="1px solid"
			borderColor="gray.300"
			borderRadius={4}
			p={2}
			pl={4}
		>
			<Text fontWeight="semibold" fontSize="lg">
				{label}
			</Text>
			<FaAngleRight />
		</Center>
	)
}
export const NavigationButtonContainer = ({
	children,
	...props
}: {
	children: ReactNode,
} & SimpleGridProps) => {
	return (
		<SimpleGrid w="100%" columns={1} spacing={2} {...props}>
			{children}
		</SimpleGrid>
	)
}
