import { ListItem, Textarea, UnorderedList, useDisclosure } from "@chakra-ui/react"
import { AlertDialog, formatDate, Link, ModalDialog, Table, TableButton, Tile, useBreadcrumbs, useErrorToast } from "@kevea/react-components"
import { PrintModal } from "components/PrintModal"
import { TableMedicalIncident } from "models/medicalIncident"
import { Patient } from "models/patient"
import patientRoutes from "pages/patients/routes"
import { useMemo, useState } from "react"
import { FaNotesMedical } from "react-icons/fa"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { Column, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table"
import { MedicalIncidentService } from "services/medicalIncident"
import { PatientService } from "services/patient"
import medicalIncidentRoutes from "./routes"

export const MedicalIncidentList = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const breadcrumbs = useBreadcrumbs([...patientRoutes, ...medicalIncidentRoutes])
	const [isDeleting, setIsDeleting] = useState(false)
	const deleteDisclosure = useDisclosure()
	const previewDisclosure = useDisclosure()
	const [previewIncident, setPreviewIncident] = useState<TableMedicalIncident>()
	const printDisclosure = useDisclosure()
	const [loading, setLoading] = useState(false)
	const error = useErrorToast()

	const handlePrintDisclosureOnClose = (dateRange?: [Date, Date]) => {
		if (dateRange) {
			setLoading(true)
			printDisclosure.onClose()
			MedicalIncidentService.printRange(patient._id, dateRange[0], dateRange[1])
				.then(blob => {
					if (blob) window.open(URL.createObjectURL(blob), '_blank')
					setLoading(false)
				})
				.catch(e => {
					error({ description: 'Wystąpił błąd podczas generowania wydruku' })
					setLoading(false)
				})
		} else {
			printDisclosure.onClose()
		}
	}

	const fetchMedicalIncidents = async () => {
		if (patientId) {
			return MedicalIncidentService.getAll(patientId).then(res => res.data)
		}
	}

	const handleRemove = async () => {
		if (tableInstance.selectedFlatRows.length) {
			setIsDeleting(true)
			const promises = []
			for (let incident of tableInstance.selectedFlatRows.map(x => x.original)) {
				promises.push(MedicalIncidentService.delete(incident._id, patientId))
			}
			await Promise.all(promises)
			setIsDeleting(false)
			refetchMedicalIncidents()
		}
		tableInstance.toggleAllRowsSelected(false)
		deleteDisclosure.onClose()
	}

	const {
		data: medicalIncidents,
		isLoading,
		refetch: refetchMedicalIncidents,
	} = useQuery(['fetchMedicalIncidents'], () =>
		fetchMedicalIncidents(),
	)

	const { data: patient, isLoading: isLoadingPatient } = useQuery(
		'fetchPatientForMedicalIncident',
		() =>
			patientId ? PatientService.get(patientId).then(res => res.data) : null,
	)

	const data = useMemo(
		() => medicalIncidents?.map(incident => new TableMedicalIncident(incident)) ?? [],
		[medicalIncidents],
	)

	const columns = useMemo<Column<TableMedicalIncident>[]>(
		() => [
			{
				Header: 'Data zdarzenia',
				accessor: 'date',
				Cell: ({ value, row }) => (
					<Link
						to={
							row.original.original._id
						}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Notatka', accessor: 'note', Cell: ({ value, row }) => value.length < 200 ? value :
					<Link onClick={() => {
						setPreviewIncident(row.original)
						previewDisclosure.onOpen()
					}}>{value.slice(0, 200)}...</Link>
			},
			{ Header: 'Dodano przez', accessor: 'addedBy' },
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)


	return <Tile
		breadcrumbs={breadcrumbs.length === 0 ? undefined : breadcrumbs}
		header={
			patient
				? `${Patient.getFullName(patient)} - Lista Zdarzeń Medycznych`
				: 'Lista Zdarzeń Medycznych`'
		}
		headerIcon={<FaNotesMedical />}
	>
		{printDisclosure.isOpen && (
			<PrintModal
				isOpen={printDisclosure.isOpen}
				onClose={handlePrintDisclosureOnClose}
				header="Wydruk zdarzeń"
				headingText="Wybierz okres zdarzeń do wydruku"
			/>
		)}
		{previewIncident &&
			<ModalDialog customFooter={<TableButton label="Zamknij" action={() => {
				previewDisclosure.onClose()
				setPreviewIncident(undefined)
			}} />} isOpen={previewDisclosure.isOpen} onClose={() => {
				previewDisclosure.onClose()
				setPreviewIncident(undefined)
			}} header='Podgląd zdarzenia'>
				Data zdarzena: {formatDate(new Date(previewIncident.date).toISOString(), 'localeDateString')}
				<br />
				Pacjent: {previewIncident.patient}
				<br />
				Dodano przez: {previewIncident.addedBy}
				<br />
				<br />
				<Textarea value={previewIncident.note} readOnly minH={300} />
			</ModalDialog>}
		<AlertDialog isOpen={deleteDisclosure.isOpen} onClose={deleteDisclosure.onClose} isRemoving={isDeleting} header="Usuwanie zdarzeń medycznych" onRemove={handleRemove}>
			Czy na pewno chcesz usunąć wybrane zdarzenia medyczne?
			<UnorderedList>
				{tableInstance.selectedFlatRows.map(x =>
					<ListItem key={x.original._id}>
						<strong>{formatDate(new Date(x.original.date).toISOString(), 'date')}</strong> - {x.original.addedBy}
					</ListItem>)}
			</UnorderedList>
		</AlertDialog>
		<Table
			tableInstance={tableInstance}
			loading={isLoading}
			buttons={[
				{
					type: 'add',
					to: 'new',
				}, {
					type: 'print',
					action: () => {
						printDisclosure.onOpen()
					},
				},
			]}
			menuOptions={{
				multipleSelection: [{
					label: 'Usuń',
					onClick: () => {
						deleteDisclosure.onOpen()
					},
				}],
				singleSelection: [{
					label: 'Usuń',
					onClick: () => {
						deleteDisclosure.onOpen()
					},
				}]
			}}
		/>
	</Tile>
}