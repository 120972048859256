import { Address, formatDate } from '@kevea/react-components'
import { Medicine } from './dictionaries/medicine'
import * as yup from 'yup'
import { KvTime } from 'utils/kvTime'
import { PatientMedicineService } from 'services/patientMedicine'
import { PatientService } from 'services/patient'
import { renderDoseValue } from 'utils/fractions'
import { Validators } from 'utils/validators'

export type EducationLevel = 'higher' | 'postsecondary' | 'secondary' | 'vocational' | 'junior_high' | 'primary' | 'incomplete_primary' | 'unspecified';


export type PatientMaritalStatus =
	| 'singleMan'
	| 'singleWoman'
	| 'marriedMan'
	| 'marriedWoman'
	| 'divorcedMan'
	| 'divorcedWoman'
	| 'widower'
	| 'widow'

export class Disablity {
	level: 'light' | 'moderate' | 'severe' | ''
	symbols: string[]
	expiryDate: string

	constructor(disability?: Disablity) {
		this.level = disability?.level ?? ''
		this.symbols = disability?.symbols ?? []
		this.expiryDate = disability?.expiryDate
			? formatDate(disability.expiryDate, 'date')
			: ''
	}
}

export class Patient {
	constructor(patient?: Patient) {
		this._id = patient?._id
		this.firstName = patient?.firstName ?? ''
		this.lastName = patient?.lastName ?? ''
		this.fullName = patient ? Patient.getFullName(patient) : ''
		this.sex = patient?.sex ?? 'none'
		this.pesel = patient?.pesel ?? ''
		this.nip = patient?.nip ?? ''
		this.zusNumber = patient?.zusNumber ?? ''
		this.status = patient?.status ?? 0
		this.birthPlace = patient?.birthPlace ?? ''
		this.dateOfBirth = formatDate(patient?.dateOfBirth ?? '', 'date')
		this.dateOfDischarge = formatDate(patient?.dateOfDischarge ?? '', 'date')
		this.dateOfDeath = formatDate(patient?.dateOfDeath ?? '', 'date')
		this.dateOfIncapacitation = formatDate(
			patient?.dateOfIncapacitation ?? '',
			'date',
		)
		this.admissionDate = formatDate(patient?.admissionDate ?? '', 'date')
		this.idCardNumber = patient?.idCardNumber ?? ''
		this.idIssuer = patient?.idIssuer ?? ''
		this.idExpiryDate = patient?.idExpiryDate
			? formatDate(new Date(patient.idExpiryDate).toISOString(), 'date')
			: ''
		this.previousAddress = new Address(patient?.previousAddress)
		this.placeOfResidence = new Address(patient?.placeOfResidence)
		this.incapacitated = patient?.incapacitated ?? false
		this.disability = new Disablity(patient?.disability)
		this.notes = patient?.notes ?? ''
		this.description = patient?.description ?? ''
		this.medicalRecommendations = patient?.medicalRecommendations ?? ''
		this.requireDiapersOrder = patient?.requireDiapersOrder ?? false
		this.relatedPersons = patient?.relatedPersons ?? []
		this.room = patient?.room ?? ''
		this.phoneNumber = patient?.phoneNumber ?? ''
		this.incapableOfIndependentExistence =
			patient?.incapableOfIndependentExistence ?? false
		this.familyName = patient?.familyName ?? ''
		this.fatherFullName = patient?.fatherFullName ?? ''
		this.motherFullName = patient?.motherFullName ?? ''
		this.maritalStatus = patient?.maritalStatus ?? undefined
		this.allergies = patient?.allergies ?? ''
		this.educationLevel = patient?.educationLevel
	}
	_id: string | undefined
	firstName: string
	lastName: string
	fullName: string
	sex: 'male' | 'female' | 'none'
	pesel: string
	nip: string
	zusNumber: string
	status: number
	dateOfBirth: string
	dateOfDischarge: string
	birthPlace: string
	idCardNumber: string
	idIssuer: string
	idExpiryDate: string
	admissionDate: string
	dateOfDeath: string
	dateOfIncapacitation: string
	previousAddress: Address
	incapacitated: boolean
	disability: Disablity
	notes: string
	description: string
	medicalRecommendations: string
	placeOfResidence: Address
	requireDiapersOrder: boolean
	relatedPersons: IRelatedPerson[]
	room: string
	phoneNumber: string
	incapableOfIndependentExistence: boolean
	familyName: string
	fatherFullName: string
	motherFullName: string
	maritalStatus: PatientMaritalStatus
	allergies: string
	educationLevel?: EducationLevel


	static getFullName = (
		patient: Partial<Patient> & {
			firstName: string
			lastName: string
		},
	) => (patient ? `${patient.firstName} ${patient.lastName}` : '')

	static maritalStatusOptions: {
		label: string
		value: PatientMaritalStatus | undefined
	}[] = [
			{
				label: '-',
				value: undefined,
			},
			{
				label: Patient.convertMaritalStatus('singleMan'),
				value: 'singleMan',
			},
			{
				label: Patient.convertMaritalStatus('singleWoman'),
				value: 'singleWoman',
			},
			{
				label: Patient.convertMaritalStatus('marriedMan'),
				value: 'marriedMan',
			},
			{
				label: Patient.convertMaritalStatus('marriedWoman'),
				value: 'marriedWoman',
			},
			{
				label: Patient.convertMaritalStatus('divorcedMan'),
				value: 'divorcedMan',
			},
			{
				label: Patient.convertMaritalStatus('divorcedWoman'),
				value: 'divorcedWoman',
			},
			{
				label: Patient.convertMaritalStatus('widower'),
				value: 'widower',
			},
			{
				label: Patient.convertMaritalStatus('widow'),
				value: 'widow',
			},
		]

	static convertMaritalStatus(status: PatientMaritalStatus): string {
		switch (status) {
			case 'singleMan':
				return 'kawaler'
			case 'singleWoman':
				return 'panna'
			case 'marriedMan':
				return 'żonaty'
			case 'marriedWoman':
				return 'zamężna'
			case 'divorcedMan':
				return 'rozwiedziony'
			case 'divorcedWoman':
				return 'rozwiedziona'
			case 'widower':
				return 'wdowiec'
			case 'widow':
				return 'wdowa'
			default:
				return '-'
		}
	}


	static convertEducationLevel = (level: EducationLevel): string => {
		switch (level) {
			case 'higher':
				return 'Wyższe';
			case 'postsecondary':
				return 'Policealne';
			case 'secondary':
				return 'Średnie';
			case 'vocational':
				return 'Zasadnicze zawodowe';
			case 'junior_high':
				return 'Gimnazjalne';
			case 'primary':
				return 'Podstawowe';
			case 'incomplete_primary':
				return 'Niepełne podstawowe';
			case 'unspecified':
				return 'Nieustalone';
			default:
				return '-';
		}
	}

	static educationLevelOptions: {
		label: string;
		value: EducationLevel | undefined;
	}[] = [
			{
				label: '-',
				value: undefined,
			},
			{
				label: Patient.convertEducationLevel('higher'),
				value: 'higher',
			},
			{
				label: Patient.convertEducationLevel('postsecondary'),
				value: 'postsecondary',
			},
			{
				label: Patient.convertEducationLevel('secondary'),
				value: 'secondary',
			},
			{
				label: Patient.convertEducationLevel('vocational'),
				value: 'vocational',
			},
			{
				label: Patient.convertEducationLevel('junior_high'),
				value: 'junior_high',
			},
			{
				label: Patient.convertEducationLevel('primary'),
				value: 'primary',
			},
			{
				label: Patient.convertEducationLevel('incomplete_primary'),
				value: 'incomplete_primary',
			},
			{
				label: Patient.convertEducationLevel('unspecified'),
				value: 'unspecified',
			},
		];

	static convertDisablityLevel = (level: Disablity['level']): string => {
		switch (level) {
			case 'light':
				return 'Lekki'
			case 'moderate':
				return 'Umiarkowany'
			case 'severe':
				return 'Znaczny'
			default:
				return 'Brak'
		}
	}

	static convertStatus = (status: number): string => {
		switch (status) {
			case 0:
				return 'Aktywny'
			case 1:
				return 'Przyszły'
			case 2:
				return 'Były'
			case 3:
				return 'Zmarły'
			default:
				return `${status}`
		}
	}
	static convertSex = (sex: Patient['sex']): string => {
		switch (sex) {
			case 'female':
				return 'Kobieta'
			case 'male':
				return 'Mężczyzna'
			default:
				return '-'
		}
	}
	static getStatusColor = (status: number): string => {
		switch (status) {
			case 0:
				return 'active'
			case 1:
				return 'future'
			case 2:
				return 'former'
			case 3:
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptionsTable = [
		{
			label: Patient.convertStatus(0),
			value: Patient.convertStatus(0),
		},
		{
			label: Patient.convertStatus(1),
			value: Patient.convertStatus(1),
		},
		{
			label: Patient.convertStatus(2),
			value: Patient.convertStatus(2),
		},
		{
			label: Patient.convertStatus(3),
			value: Patient.convertStatus(3),
		},
	]
	static statusOptions = [
		{
			label: Patient.convertStatus(0),
			value: 0,
		},
		{
			label: Patient.convertStatus(1),
			value: 1,
		},
		{
			label: Patient.convertStatus(2),
			value: 2,
		},
		{
			label: Patient.convertStatus(3),
			value: 3,
		},
	]
	static sexOptions = [
		{
			label: Patient.convertSex('none'),
			value: 'none',
		},
		{
			label: Patient.convertSex('male'),
			value: 'male',
		},
		{
			label: Patient.convertSex('female'),
			value: 'female',
		},
	]
	static disablityLevelOptions = [
		{
			label: Patient.convertDisablityLevel(''),
			value: '',
		},
		{
			label: Patient.convertDisablityLevel('light'),
			value: 'light',
		},
		{
			label: Patient.convertDisablityLevel('moderate'),
			value: 'moderate',
		},
		{
			label: Patient.convertDisablityLevel('severe'),
			value: 'severe',
		},
	]
	static validationSchema = yup.object({
		firstName: yup.string().required('Imię jest wymagane'),
		lastName: yup.string().required('Nazwisko jest wymagane'),
		pesel: yup
			.string()
			.test('isPeselValid', 'Numer PESEL jest nieprawidłowy', pesel =>
				pesel && pesel.length > 0 ? Validators.pesel(pesel) : true,
			),
	})

	static fetchPatients = async (onlyActive: boolean = true) => {
		return PatientService.getAll()
			.then(res => res.data)
			.then(patients => {
				if (onlyActive) {
					return patients.filter(patient => patient.status === 0)
				} else {
					return patients
				}
			})
	}
}

export type TPatient = {
	_id: string | undefined
	firstName: string
	lastName: string
	sex?: 'male' | 'female' | 'none'
	pesel: string
	nip: string
	zusNumber: string
	status: number
	dateOfBirth: string
	dateOfDischarge: string
	birthPlace: string
	idCardNumber: string
	idIssuer: string
	idExpiryDate: string
	admissionDate: string
	dateOfDeath: string
	dateOfIncapacitation: string
	previousAddress: Address
	incapacitated: boolean
	disability: Disablity
	notes: string
	description: string
	medicalRecommendations: string
	placeOfResidence: Address
	requireDiapersOrder: boolean
	relatedPersons: IRelatedPerson[]
	room: string
	phoneNumber: string
	incapableOfIndependentExistence: boolean
	familyName: string
	fatherFullName: string
	motherFullName: string
	maritalStatus: PatientMaritalStatus
	educationLevel: EducationLevel
}

export class PatientTable {
	constructor(patient: Patient) {
		this._id = patient._id || ''
		this.name = Patient.getFullName(patient)
		this.pesel = patient.pesel || ''
		this.dateOfBirth = patient.dateOfBirth
			? new Date(patient.dateOfBirth).toLocaleDateString()
			: ''
		this.admissionDate = patient.admissionDate
			? new Date(patient.admissionDate).toLocaleDateString()
			: ''
		this.status = Patient.convertStatus(patient.status)
		this.disability = Patient.convertDisablityLevel(
			patient.disability?.level ?? '',
		)
		this.sex = Patient.convertSex(patient.sex ?? 'none')
		this.incapacitated = patient.incapacitated ? 'TAK' : 'NIE'
		this.original = patient
	}
	_id: string
	name: string
	pesel: string
	dateOfBirth: string
	admissionDate: string
	disability: string
	sex: string
	incapacitated: string
	status: string
	original: Patient

	static disablityLevelOptions = [
		{
			label: Patient.convertDisablityLevel('light'),
			value: Patient.convertDisablityLevel('light'),
		},
		{
			label: Patient.convertDisablityLevel('moderate'),
			value: Patient.convertDisablityLevel('moderate'),
		},
		{
			label: Patient.convertDisablityLevel('severe'),
			value: Patient.convertDisablityLevel('severe'),
		},
	]

	static sexOptions = [
		{
			label: Patient.convertSex('male'),
			value: Patient.convertSex('male'),
		},
		{
			label: Patient.convertSex('female'),
			value: Patient.convertSex('female'),
		},
	]
	static incapacitatedOptions = [
		{
			label: 'TAK',
			value: 'TAK',
		},
		{
			label: 'NIE',
			value: 'NIE',
		},
	]
}

export class RelatedPerson implements IRelatedPerson {
	name = ''
	relation = 1
	address = new Address()
	emailAddress = ''
	phoneNumber = ''

	static convertRelation = (relation: number): string => {
		switch (relation) {
			case 0:
				return 'Opiekun prawny'
			case 1:
				return 'Rodzina'
			case 2:
				return 'Znajomy'
			case 3:
				return 'Inny'
			default:
				return `${relation}`
		}
	}

	static relationOptions = [
		{
			label: RelatedPerson.convertRelation(1),
			value: 1,
		},
		{
			label: RelatedPerson.convertRelation(0),
			value: 0,
		},
		{
			label: RelatedPerson.convertRelation(2),
			value: 2,
		},
		{
			label: RelatedPerson.convertRelation(3),
			value: 3,
		},
	]

	static validationSchema = yup.object({
		name: yup.string().required('Imię i nazwisko jest wymagane'),
	})
}

export interface IRelatedPerson {
	name: string
	emailAddress?: string
	phoneNumber?: string
	address?: Address
	relation: number
}

export class PatientMedicine {
	constructor(patientMedicine: PatientMedicine) {
		this._id = patientMedicine._id
		this.description = patientMedicine.description ?? ''
		this.medicine = patientMedicine.medicine
		this.dose =
			patientMedicine.dose ?? !patientMedicine.preciseDose
				? new NotPreciseDose()
				: undefined
		this.preciseDose = patientMedicine.preciseDose
		this.patient = patientMedicine.patient
		this.medicineId = patientMedicine.medicineId
		this.isPrecise = patientMedicine.isPrecise ?? false
		this.endDate = formatDate(patientMedicine?.endDate ?? '', 'date')
		this.startDate = formatDate(
			patientMedicine?.startDate ?? new Date().toString(),
			'date',
		)
		this.status = '' ?? patientMedicine.medicine.status
	}
	_id?: string | undefined
	description?: string
	medicine?: Medicine | undefined
	dose?: NotPreciseDose
	preciseDose?: IPatientMedicineDose[]
	isPrecise?: boolean
	patient: Patient
	medicineId?: string | undefined
	startDate?: string
	endDate?: string
	status?: string

	static convertDose = (dose: Dose | undefined): string => {
		if (Array.isArray(dose)) {
			return dose
				.sort((a, b) => a.dayTime - b.dayTime)
				.map(
					dose =>
						`${renderDoseValue(dose.amount)} x ${KvTime.getTimeFromMinutes(
							dose.dayTime,
						)}`,
				)
				.join('\n')
		} else if (dose) {
			return `${renderDoseValue(dose.morning)} - ${renderDoseValue(
				dose.noon,
			)} - ${renderDoseValue(dose.evening)}`
		} else {
			return ''
		}
	}
	static fetchPatientMedicines = async (patient: Patient) => {
		if (patient?._id) {
			return PatientMedicineService.getAll(patient._id).then(res => res.data)
		}
	}
	static validationSchema = yup.object({
		startDate: yup.string().required('Podaj datę'),
	})
}

export class TablePatientMedicine {
	constructor(medicine: PatientMedicine) {
		this._id = medicine._id ?? ''
		this.name = medicine.medicine
			? Medicine.getNameWithDose(medicine.medicine)
			: ''
		this.description = medicine.description ?? ''
		this.dose = PatientMedicine.convertDose(
			medicine.isPrecise ? medicine.preciseDose : medicine.dose,
		)
		this.medicine = medicine.medicine
		this.original = medicine
		this.endDate = formatDate(medicine?.endDate ?? '', 'localeDateString')
		this.startDate = formatDate(medicine?.startDate ?? '', 'localeDateString')
		this.status = Medicine.convertStatus(medicine.status)
	}

	_id: string
	name: string
	dose: string
	description: string
	medicine?: Medicine
	original: PatientMedicine
	startDate?: string
	endDate?: string
	status?: string
}

export class NotPreciseDose implements IPatientNotPreciseMedicineDose {
	morning: number = 0
	noon: number = 0
	evening: number = 0
}

export type Dose = IPatientMedicineDose[] | IPatientNotPreciseMedicineDose

export interface IPatientMedicineDose {
	amount: number
	dayTime: number
}
export interface IPatientNotPreciseMedicineDose {
	morning: number
	noon: number
	evening: number
}
