export class Cookie {
	static set(name: string, value: string, days: number = 30, path: string = "/"): void {
		const expires = new Date();
		expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000)); // Add days to current date
		const expiresString = `expires=${expires.toUTCString()}`;
		document.cookie = `${name}=${encodeURIComponent(value)}; ${expiresString}; path=${path}; SameSite=Strict`;
	}
	static get(name: string): string | null {
		const nameEQ = `${name}=`;
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return decodeURIComponent(c.substring(nameEQ.length, c.length));
			}
		}
		return null;
	}
	static delete(name: string, path: string = "/"): void {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
	}
	static check(name: string): boolean {
		return Cookie.get(name) !== null;
	}
}