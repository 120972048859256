import { formatDate } from '@kevea/react-components'
import { Patient } from './patient'

export class MedicalIncident {
	constructor(medicalIncident: MedicalIncident) {
		this._id = medicalIncident._id
		this.note = medicalIncident.note ?? ''
		this.date = medicalIncident.date ? formatDate(new Date(medicalIncident.date).toISOString(), 'date') : formatDate(new Date().toISOString(), 'date')
		this.patient = medicalIncident.patient
		this.doctorName = medicalIncident.doctorName ?? ''
		this.doctorTitle = medicalIncident.doctorTitle ?? ''
		this.doctorSpecialization = medicalIncident.doctorSpecialization ?? ''
	}
	_id?: string | undefined
	date?: string
	patient: Patient
	note?: string
	doctorName?: string
	doctorTitle?: string
	doctorSpecialization?: string
}

export class TableMedicalIncident {
	constructor(medicalIncident: MedicalIncident) {
		this._id = medicalIncident._id
		this.note = medicalIncident.note ?? ''
		this.date = formatDate(medicalIncident.date ?? '', 'localeDateString')
		this.filterDate = formatDate(medicalIncident.date ?? '', 'date')
		this.patient = medicalIncident.patient ? Patient.getFullName(medicalIncident.patient) : ''
		this.addedBy = `${medicalIncident.doctorTitle}${medicalIncident.doctorSpecialization ? ' ' + medicalIncident.doctorSpecialization : ''} - ${medicalIncident.doctorName}`
		this.original = medicalIncident
	}
	_id: string | undefined
	date: string
	filterDate: string
	note: string
	patient: string
	addedBy: string
	original: MedicalIncident
}

export type TMedicalIncident = {
	_id: string
	date: string
	patient: Patient
	note: string
	doctorName: string
	doctorTitle: string
	doctorSpecialization?: string

}