import {
	InputField,
	InputSpacingWrapper,
	ModalDialog,
	TextAreaField,
	useErrorToast,
} from '@kevea/react-components'
import { FileInput } from 'components/FileInput'
import { Form, Formik, FormikProps } from 'formik'
import { Attachment } from 'models/attachment'
import { useRef, useState } from 'react'
import { AttachmentService, AttachmentType } from 'services/attachment'

type Props = {
	attachmentType: AttachmentType
	oid: string
	isOpen: boolean
	onClose: () => void
	selectedAttachment?: Attachment
	parentPath?: string
	parentId?: string
}

export const AttachmentModal = (props: Props) => {
	const formik = useRef<FormikProps<Attachment>>(null)
	const [loading, setLoading] = useState(false)
	const [file, setFile] = useState<File | undefined>()
	const error = useErrorToast()

	const handleSubmit = (values: Attachment) => {
		if (formik.current) {
			setLoading(true)
			if (props.selectedAttachment?._id) {
				AttachmentService.put(props.oid, props.attachmentType, values, props.parentPath, props.parentId).then(
					() => {
						setLoading(false)
						props.onClose()
					},
				)
			} else {
				if (file) {
					AttachmentService.post(props.oid, props.attachmentType, values, props.parentPath, props.parentId)
						.then(res => res.data)
						.then(attachment =>
							AttachmentService.uploadFile(
								props.oid,
								props.attachmentType,
								attachment,
								file,
								props.parentPath,
								props.parentId,
							).then(() => {
								setLoading(false)
								props.onClose()
							}),
						)
				} else {
					error({ description: 'Musisz dodać plik do załącznika' })
					setLoading(false)
				}
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={
						props.selectedAttachment ? 'Edytuj załącznik' : 'Dodaj załącznik'
					}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Attachment.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedAttachment
								? new Attachment(props.selectedAttachment)
								: new Attachment()
						}
					>
						<Form>
							<InputSpacingWrapper>
								<InputField
									name="name"
									placeholder="Nazwa"
									label="Nazwa"
									isRequired
								/>
								<TextAreaField
									name="description"
									placeholder="Opis"
									label="Opis"
								/>
								{!props.selectedAttachment?._id && (
									<FileInput
										showProgress={loading}
										onDrop={files => setFile(files[0])}
									/>
								)}
							</InputSpacingWrapper>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
