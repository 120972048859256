import { GridItem, Textarea } from "@chakra-ui/react"
import { DataDisplay, DataDisplayRow, Label, Tile, TileEditButton, TilePrintButton } from "@kevea/react-components"
import { NavigateButton, NavigationButtonContainer } from "components/NavigateButton"
import { TMedicalIncident } from "models/medicalIncident"
import { Patient } from "models/patient"
import { useMemo } from "react"
import { useOutletContext } from "react-router"
import { MedicalIncidentService } from "services/medicalIncident"
import { MedicalIncidentContext } from "../MedicalIncident"

export const General = () => {
	const { medicalIncident, refetch, ...rest } = useOutletContext<MedicalIncidentContext>()

	const rows = useMemo<DataDisplayRow<TMedicalIncident>[]>(
		() => [
			{
				accessor: 'patient',
				label: 'Pacjent',
				render: p => Patient.getFullName(p),
			},
			{
				accessor: 'date',
				label: 'Data zdarzenia',
				type: 'date',
			},
			{
				accessor: 'doctorName',
				label: 'Dodane przez',
				render(_, object) {
					return `${object.doctorTitle}${object.doctorSpecialization ? ' ' + object.doctorSpecialization : ''} - ${object.doctorName}`
				},
			}
		],
		[],
	)

	return medicalIncident ? (<Tile
		grid
		{...rest}
		headerButtons={
			<>
				<TilePrintButton
					options={[
						{
							fileName: '',
							label: 'Wydruk zdarzenia',
							promiseFunction: () => MedicalIncidentService.print(medicalIncident._id, medicalIncident.patient?._id),
						},
					]}
				/>
				<TileEditButton />
			</>
		}
	>
		<GridItem colSpan={{ base: 2, xl: 1 }}>
			<DataDisplay data={medicalIncident} rows={rows} />
			<Label>Notatka</Label>
			<Textarea readOnly value={medicalIncident.note} minH={280} />
		</GridItem>
		<GridItem colSpan={{ base: 2, xl: 1 }}>
			<NavigationButtonContainer>
				<NavigateButton label="Załączniki" to="attachments" />
			</NavigationButtonContainer>
		</GridItem>
	</Tile>
	) : null
}