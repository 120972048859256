import { useNavigate, useOutletContext, useParams } from "react-router"
import { MedicalIncidentContext } from "../MedicalIncident"
import * as yup from 'yup'
import { type } from "os"
import { useCallback, useMemo, useRef, useState } from "react"
import { Cookie } from "utils/cookie"
import { Form, Formik, FormikProps } from "formik"
import { InputField, SelectField, SwitchField, TextAreaField, Tile, TileCancelButton, TileSaveButton } from "@kevea/react-components"
import { GridItem, Heading } from "@chakra-ui/react"
import { AxiosResponse } from "axios"
import { MedicalIncident } from "models/medicalIncident"
import { MedicalIncidentService } from "services/medicalIncident"
import { ComboBox, ComboBoxField, ComboBoxItem } from "components/ComboBox"
import { specjalizacjeLekarskie, specjalizacjeLekarskoDentystyczne } from "./specializations"

const validationSchema = yup.object({
	date: yup.string().required('Data jest wymagana'),
	note: yup.string().required('Notatka jest wymagana'),
	doctorName: yup.string().required('Imię i nazwisko jest wymagane'),
	doctorTitle: yup.string().required('Tytuł jest wymagany'),
	doctorSpecialization: yup.string(),
	remember: yup.boolean(),
})

type FormikValues = yup.Asserts<typeof validationSchema>

const doctorTitles = [
	"Lekarz",
	"Lekarz dentysta",
] as const

type DoctorValues = {
	title: string
	name: string
	specialization: string
	remember: boolean
}

const getDoctorValuesFromCookies = (): DoctorValues | undefined => {
	const doctorName = Cookie.get('doctorName')
	const doctorTitle = Cookie.get('doctorTitle')
	const doctorSpecialization = Cookie.get('doctorSpecialization')
	if (doctorName && doctorTitle) {
		return {
			title: doctorTitle,
			name: doctorName,
			specialization: doctorSpecialization ?? '',
			remember: true,
		}
	}
	return undefined
}

const setDoctorValuesToCookies = (doctorValues?: DoctorValues) => {
	if (doctorValues) {
		Cookie.set('doctorName', doctorValues.name, 1)
		Cookie.set('doctorTitle', doctorValues.title, 1)
		Cookie.set('doctorSpecialization', doctorValues.specialization, 1)
	} else {
		Cookie.delete('doctorName')
		Cookie.delete('doctorTitle')
		Cookie.delete('doctorSpecialization')
	}
}

export const GeneralEdit = () => {
	const { medicalIncident, refetch, ...rest } = useOutletContext<MedicalIncidentContext>()
	const { id: patientId } = useParams<{ id: string }>()
	const [loading, setLoading] = useState(false)
	const formik = useRef<FormikProps<FormikValues>>(null)
	const navigate = useNavigate()

	const getAvailableSpecializations = useCallback((doctorTitle: string) => {
		if (doctorTitle === 'Lekarz dentysta') return specjalizacjeLekarskoDentystyczne
		return specjalizacjeLekarskie
	}, [])

	const initialDoctorValues = useMemo<DoctorValues>(() => {
		if (medicalIncident.doctorName) {
			return {
				name: medicalIncident.doctorName,
				title: medicalIncident.doctorTitle,
				specialization: medicalIncident.doctorSpecialization,
				remember: true,
			}
		}
		const valuesFromCookies = getDoctorValuesFromCookies()
		if (valuesFromCookies) {
			return valuesFromCookies
		}
		return {
			title: doctorTitles[0],
			name: '',
			specialization: '',
			remember: false,
		}
	}, [])

	const handleSubmit = async (values: FormikValues) => {
		const afterSubmit = async (response?: AxiosResponse<MedicalIncident>) => {
			const newIncident = response?.data
			if (newIncident) {
				setLoading(false)
				navigate(`/patients/${patientId}/medicalIncidents/${newIncident._id}`)
				refetch?.()
			} else {
				setLoading(false)
				navigate(`/patients/${patientId}/medicalIncidents`)
			}
		}
		setLoading(true)
		if (values.remember) {
			setDoctorValuesToCookies({ name: values.doctorName, title: values.doctorTitle, specialization: values.doctorSpecialization, remember: true })
		} else {
			setDoctorValuesToCookies(undefined)
		}
		const newIncident: MedicalIncident = {
			_id: medicalIncident?._id,
			date: values.date,
			note: values.note,
			doctorName: values.doctorName,
			doctorTitle: values.doctorTitle,
			doctorSpecialization: values.doctorSpecialization,
			patient: medicalIncident.patient,
		}
		if (medicalIncident?._id) {
			MedicalIncidentService.put(newIncident, patientId).then(afterSubmit)
		} else {
			MedicalIncidentService.post(newIncident, patientId).then(afterSubmit)
		}

	}


	return <Formik onSubmit={handleSubmit} initialValues={{
		doctorName: initialDoctorValues.name,
		doctorTitle: initialDoctorValues.title,
		doctorSpecialization: initialDoctorValues.specialization,
		date: medicalIncident.date,
		note: medicalIncident.note,
		remember: initialDoctorValues.remember,
	}} innerRef={formik} validationSchema={validationSchema}>
		{({ values, setFieldValue }) => (
			<Form>
				<Tile
					grid
					{...rest}
					headerButtons={
						<>
							<TileSaveButton
								onSave={() => formik.current?.submitForm()}
								isLoading={loading}
							/>
							<TileCancelButton
								isLoading={loading}
								onCancel={() => navigate('..')}
							/>
						</>
					}
				>
					<GridItem colSpan={{ base: 2, xl: 1 }}>
						<InputField name="date" label="Data" type="date" />
						<TextAreaField name="note" label="Notatka" minH={280} />
					</GridItem>
					<GridItem colSpan={{ base: 2, xl: 1 }} border="1px solid" borderColor={'gray.200'} padding={4} borderRadius={8}>
						<Heading size={'md'}>Dane lekarza</Heading>
						<InputField name="doctorName" label="Imię i nazwisko" />
						<SelectField name="doctorTitle" label="Tytuł zawodowy" options={doctorTitles.map(x => ({ label: x, value: x }))} />
						<ComboBoxField placeholder="Wybierz specjalizację" data={getAvailableSpecializations(values.doctorTitle)} label="Specjalizacja" name="doctorSpecialization" render={x => x.value} />
						<SwitchField name="remember" label="Zapamiętaj dane lekarza" />
					</GridItem>
				</Tile>
			</Form>)}
	</Formik>
}