import {
	ModalDialog,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import React, { useRef } from 'react'

export type PrintOptions = {
	month: number
	year: number
}

const months: { value: number; label: string }[] = [
	{ value: 1, label: 'styczeń' },
	{ value: 2, label: 'luty' },
	{ value: 3, label: 'marzec' },
	{ value: 4, label: 'kwiecień' },
	{ value: 5, label: 'maj' },
	{ value: 6, label: 'czerwiec' },
	{ value: 7, label: 'lipiec' },
	{ value: 8, label: 'sierpień' },
	{ value: 9, label: 'wrzesień' },
	{ value: 10, label: 'październik' },
	{ value: 11, label: 'listopad' },
	{ value: 12, label: 'grudzień' },
]

const years = Array.from(
	{ length: Math.abs(2022 - new Date().getFullYear()) + 2 },
	(_, i) => i + 2022,
).map(x => ({ value: x, label: x.toString() }))

type Props = {
	isOpen: boolean
	onClose: (options?: PrintOptions) => void
}

export const PrintListModal = ({ isOpen, onClose }: Props) => {
	const formik = useRef<FormikProps<PrintOptions>>(null)
	const handleSave = (values: PrintOptions) => {
		onClose(values)
	}

	return (
		<ModalDialog
			header="Wydruk listy pacjentów"
			isOpen={isOpen}
			onClose={() => onClose()}
			confirmButtonLabel="Drukuj"
			onSave={() => formik.current?.handleSubmit()}
		>
			<Formik
				innerRef={formik}
				onSubmit={handleSave}
				initialValues={{ month: new Date().getMonth() + 1, year: new Date().getFullYear() }}
			>
				<Form>
					<SelectField label="Miesiąc" name="month" options={months} />
					<SelectField label="Rok" name="year" options={years} />
				</Form>
			</Formik>
		</ModalDialog>
	)
}
