import { GridItem, useDisclosure } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Tile,
	TileEditButton,
	TilePrintButton,
	MenuButton,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { DiapersOrder, TDiapersOrder } from 'models/diapersOrder'
import { Patient } from 'models/patient'
import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { DiapersOrderService } from 'services/diapersOrder'
import { DiapersOrderContext } from '../DiapersOrder'
import { DiapersOrderModal } from '../DiapersOrderModal'
import {
	DiapersOrderStatusChanger,
	DiapersOrderStatusChangerContext,
} from '../DiapersOrderStatusChanger'

export const General = () => {
	const { diapersOrder, refetch, ...rest } =
		useOutletContext<DiapersOrderContext>()
	const openDisclosure = useDisclosure()

	const rows = useMemo<DataDisplayRow<TDiapersOrder>[]>(
		() => [
			{
				accessor: 'patient',
				label: 'Pacjent',
				render: (patient: Patient) => Patient.getFullName(patient),
			},
			{ accessor: 'prescriptionNumber', label: 'Numer NFZ' },
			{ accessor: 'dateOfIssue', label: 'Data wystawienia', type: 'date' },
			{ accessor: 'startDate', label: 'Data rozpoczęcia', type: 'date' },
			{ accessor: 'finishDate', label: 'Data ważności', type: 'date' },
		],
		[],
	)

	return diapersOrder && refetch ? (
		<DiapersOrderStatusChanger diapersOrder={diapersOrder} refetch={refetch}>
			<DiapersOrderModal
				isOpen={openDisclosure.isOpen}
				onClose={() => {
					openDisclosure.onClose()
					refetch()
				}}
				diapersOrder={diapersOrder}
			/>
			<DiapersOrderStatusChangerContext.Consumer>
				{({ isLoading, menuOptions }) => (
					<Tile
						isLoading={isLoading}
						grid
						{...rest}
						headerButtons={
							<>
								<MenuButton options={menuOptions} />
								<TilePrintButton
									options={[
										{
											fileName: 'Zlecenie ' + diapersOrder.number,
											label: 'Wydruk zlecenia',
											promiseFunction: () =>
												DiapersOrderService.print(diapersOrder._id ?? ''),
										},
									]}
								/>
								{DiapersOrder.checkStatus([0, 1], diapersOrder) && (
									<TileEditButton customAction={openDisclosure.onOpen} />
								)}
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<DataDisplay data={diapersOrder} rows={rows} />
						</GridItem>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<NavigationButtonContainer>
								<NavigateButton label="Załączniki" to="attachments" />
							</NavigationButtonContainer>
						</GridItem>
					</Tile>
				)}
			</DiapersOrderStatusChangerContext.Consumer>
		</DiapersOrderStatusChanger>
	) : null
}
