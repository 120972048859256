import { IUser } from 'models/user'
import { getUser, getUserPermissions } from 'services'
import { AuthenticationService } from 'services/authentication'

export const getFullName = (user?: IUser): string => {
	if (user) return `${user.firstName} ${user.lastName ?? ''}`
	return ''
}

export const restrict = (
	module:
		| 'patient'
		| 'report'
		| 'orders'
		| 'nfz_orders'
		| 'contracts'
		| 'bills'
		| 'warehouse'
		| 'menu'
		| 'settings'
		| 'event_log',
	power:
		| 'view'
		| 'bills'
		| 'warehouse'
		| 'deposits'
		| 'edit'
		| 'measurements'
		| 'notes'
		| 'patientMedicines'
		| 'taskPlans'
		| 'tasks'
		| 'contractTemplates'
		| 'devices'
		| 'users'
		| 'dictionaries'
		| 'attachments'
		| 'medicalIncidents',
	strict?: boolean,
): boolean => {
	const perms = getUserPermissions()
	if (strict && !perms) return false
	if (perms === undefined) return true
	if (perms[module] === undefined) return false
	// @ts-expect-error
	return perms[module].includes(power)
}
