import { formatDate, OutletContext, TileGrid, useBreadcrumbs, useNotFoundToast } from "@kevea/react-components"
import { MedicalIncident as MedicalIncidentModel } from "models/medicalIncident"
import patientRoutes from "pages/patients/routes"
import { useEffect } from "react"
import { useQuery } from "react-query"
import { Outlet, useNavigate, useParams } from "react-router"
import { MedicalIncidentService } from "services/medicalIncident"
import { PatientService } from "services/patient"
import medicalIncidentRoutes from "./routes"

export type MedicalIncidentContext = OutletContext & {
	medicalIncident: MedicalIncidentModel | undefined
}
export const MedicalIncident = () => {
	const { id: patientId, incidentId } = useParams<{ id: string, incidentId: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchIncident = async (): Promise<MedicalIncidentModel> => {
		if (patientId) {
			if (incidentId && incidentId !== 'new') {
				const incident = await MedicalIncidentService.get(incidentId, patientId).then(res => res.data)
				if (!incident) {
					toast()
					navigate('..')
				}
				return new MedicalIncidentModel(incident)
			} else {
				const patient = await PatientService.get(patientId).then(res => res.data)
				if (!patient) {
					toast()
					navigate('..')
				}
				return new MedicalIncidentModel({ patient: patient })
			}
		} else {
			toast()
			navigate('..')
		}
	}

	const {
		data: incident,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchMedicalIncident', fetchIncident)

	const breadcrumbs = useBreadcrumbs(
		[...patientRoutes, ...medicalIncidentRoutes]
	)

	const context: MedicalIncidentContext = {
		breadcrumbs: breadcrumbs,
		medicalIncident: incident,
		titleElement: incident?._id ? <>{formatDate(new Date(incident.date).toISOString(), 'localeDateString')}</> : null,
		refetch,
	}

	useEffect(() => {
		refetch()
	}, [incidentId])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}