import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {
	Dropdown,
	Label,
	ModalDialog,
	SelectField,
	SwitchField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Patient } from 'models/patient'
import React, { useRef, useState } from 'react'

export type PrintOptions = {
	onlyCompleted: boolean
}

type Props = {
	isOpen: boolean
	onClose: (options?: PrintOptions) => void
}

export const PatientPrintListModal = ({ isOpen, onClose }: Props) => {
	const formik = useRef<FormikProps<PrintOptions>>(null)
	const handleSave = (values: PrintOptions) => {
		onClose(values)
	}

	return (
		<ModalDialog
			header="Wydruk listy pacjentów"
			isOpen={isOpen}
			onClose={() => onClose()}
			confirmButtonLabel="Drukuj"
			onSave={() => formik.current?.handleSubmit()}
		>
			<Formik
				innerRef={formik}
				onSubmit={handleSave}
				initialValues={{ onlyCompleted: false }}
			>
				<Form>
					<SwitchField name='onlyCompleted' label='Tylko wykonane zadania' />
				</Form>
			</Formik>
		</ModalDialog>
	)
}
