import { Attachment } from 'models/attachment'
import { getBaseInstancePath as getBaseInstancePathCore, printConfig, Service } from 'services'
import http from './axios'

export type AttachmentType =
	| 'patients'
	| 'diapersOrders'
	| 'medicines'
	| 'products'
	| 'contracts'
	| 'orders'
	| 'bills'
	| 'medicalIncidents'

const getBaseInstancePath = (path: string, parentPath?: string, parentId?: string): string => {
	if (parentPath && parentId) {
		return getBaseInstancePathCore(`/${parentPath}/${parentId}${path}`)
	} else {
		return getBaseInstancePathCore(path)
	}
}

export class AttachmentService {
	static getAll(oid: string, type: AttachmentType, parentPath?: string, parentId?: string) {
		return http.get<Attachment[]>(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments`,
		)
	}
	static get(oid: string, type: AttachmentType, _id: string, parentPath?: string, parentId?: string) {
		return http.get<Attachment>(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments/${_id}`,
		)
	}
	static put(oid: string, type: AttachmentType, attachment: Attachment, parentPath?: string, parentId?: string) {
		return http.put<Attachment>(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments/${attachment._id}`,
			attachment,
		)
	}
	static post(oid: string, type: AttachmentType, attachment: Attachment, parentPath?: string, parentId?: string) {
		return http.post<Attachment>(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments`,
			attachment,
		)
	}
	static delete(oid: string, type: AttachmentType, attachment: Attachment, parentPath?: string, parentId?: string) {
		return http.delete(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments/${attachment._id}`,
		)
	}
	static downloadFile(oid: string, type: AttachmentType, _id: string, parentPath?: string, parentId?: string) {
		return http.get<Blob>(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments/${_id}/file`,
			printConfig,
		)
	}
	static uploadFile(
		oid: string,
		type: AttachmentType,
		attachment: Attachment,
		file: File,
		parentPath?: string,
		parentId?: string
	) {
		let formData = new FormData()
		formData.append('file', file)
		return http.post(
			`${getBaseInstancePath(`/${type}`, parentPath, parentId)}/${oid}/attachments/${attachment._id
			}/file`,
			formData,
			printConfig,
		)
	}
}
