import {
	Tile,
	InputField,
	TileSaveButton,
	TileCancelButton,
	Label,
	Dropdown,
	formatDate,
	Link,
	Table,
	SelectField,
	useErrorToast,
} from '@kevea/react-components'
import { Axios, AxiosResponse } from 'axios'
import { Formik, Form, FormikProps, useField } from 'formik'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import {
	Box,
	Button,
	Text,
	useDisclosure,
	Select,
	FormErrorMessage,
	FormControl,
	GridItem,
} from '@chakra-ui/react'
import { BillContext } from '../Bill'
import { Bill, BillEntry } from 'models/bill'
import { BillService } from 'services/bill'
import { useQuery } from 'react-query'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { Column, useRowSelect, useTable } from 'react-table'
import { AddEntryModal } from './AddEntryModal'
import { AttachmentService } from 'services/attachment'
import { Attachment } from 'models/attachment'

type Props = {}

export const GeneralEdit = (props: Props) => {
	const { bill, refetch, ...rest } = useOutletContext<BillContext>()
	const [entries, setEntries] = useState(bill?.entries ?? [])

	const { id: patientId } = useParams<{ id: string }>()
	const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
		bill?.patient,
	)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const formik = useRef<FormikProps<Bill>>(null)
	const showAllPatientsDisclosure = useDisclosure()
	const addEntryDisclosure = useDisclosure()
	const [selectedEntry, setSelectedEntry] = useState<BillEntry | undefined>()
	const error = useErrorToast()

	const { data: patientsRaw } = useQuery('fetchPatients', () =>
		Patient.fetchPatients(),
	)

	const patients = useMemo(
		() =>
			patientsRaw
				?.map(patient => new Patient(patient))
				.filter(patient => patient) ?? [],
		[patientsRaw],
	)
	useEffect(() => {
		if (patientId && patients) {
			const patient = patients.find(p => p._id === patientId)
			if (patient) {
				changePatient(patient)
			}
		} else if (bill?.patient) {
			changePatient(bill.patient)
		}
	}, [patients, patientId])

	const changePatient = (patient?: Patient) => {
		formik.current?.setFieldValue(
			'patient',
			patient ? new Patient(patient) : undefined,
		)
		setSelectedPatient(new Patient(patient))
	}

	const handleSubmit = async (values: Bill) => {
		const afterSubmit = async (response?: AxiosResponse<Bill>) => {
			const entriesWithAttachmentsToUpload = entries.filter(e =>
				Boolean(e.fileToUpload),
			)
			if (entriesWithAttachmentsToUpload.length > 0) {
				const uploadPromises: Promise<{ a: Attachment; e: BillEntry }>[] = []
				for (let e of entriesWithAttachmentsToUpload) {
					const attachmentName = e.invoiceNumber
						? `${e.name} (${e.invoiceNumber})`
						: e.name
					uploadPromises.push(
						AttachmentService.post(response.data._id, 'bills', {
							fileName: attachmentName,
							name: attachmentName,
						})
							.then(res => {
								e.attachmentId = res.data._id
								return { a: res.data, e }
							})
							.then(async ({ a, e }) => {
								await AttachmentService.uploadFile(
									response.data._id,
									'bills',
									a,
									e.fileToUpload,
								)
								return { a, e }
							}),
					)
				}
				const entriesToUpdate = await Promise.all(uploadPromises)
				const billWithEntriesToUpdate = response.data
				for (let e of entriesToUpdate) {
					billWithEntriesToUpdate.entries.find(
						x => x.no === e.e.no,
					).attachmentId = e.a._id
				}
				await BillService.put(billWithEntriesToUpdate).then(bill => {
					return bill
				})
			}
			setLoading(false)
			navigate(
				patientId
					? `/patients/${patientId}/bills/`
					: '/bills/' + response?.data?._id ?? '',
			)
			refetch?.()
		}
		setLoading(true)
		const newBill: Bill = {
			...values,
			entries,
		}
		if (bill?._id) {
			BillService.put(newBill)
				.then(bill => {
					return bill
				})
				.then(afterSubmit)
		} else {
			BillService.post(newBill).then(afterSubmit)
		}
	}

	const downloadFile = async (_id: string): Promise<Blob | undefined> => {
		if (bill?._id) {
			return AttachmentService.downloadFile(bill._id, 'bills', _id)
				.then(res => res.data)
				.catch(() => {
					error({ description: 'Wystąpił błąd podczas pobierania pliku' })
					return undefined
				})
		}

		return undefined
	}

	const columns = useMemo<Column<BillEntry>[]>(
		() => [
			{
				Header: 'Lp.',
				accessor: 'no',
			},
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: ({ row, value }) => (
					<Link
						onClick={() => {
							setSelectedEntry(row.original)
							addEntryDisclosure.onOpen()
						}}
					>
						{row.original.type === 'invoice'
							? `${value} (${row.original.invoiceNumber})`
							: value}
					</Link>
				),
			},
			// {
			// 	Header: 'Ilość',
			// 	accessor: 'quantity',
			// },
			{
				Header: 'Cena',
				accessor: 'price',
				Cell: ({ value }) => `${value?.toFixed(2) ?? ''} zł`,
			},
			// {
			// 	Header: 'Wartość',
			// 	accessor: 'amount',
			// 	Cell: ({ value }) => `${value?.toFixed(2) ?? ''} zł`,
			// },
			{
				Header: 'Załącznik',
				accessor: 'attachmentId',
				Cell: ({ row }) =>
					row.original.attachmentId || row.original.fileToUpload ? (
						<Link
							onClick={() => {
								const attachmentId = row.original.attachmentId
								if (row.original.fileToUpload) {
									window.open(
										URL.createObjectURL(row.original.fileToUpload),
										'_blank',
									)
								} else if (attachmentId) {
									downloadFile(attachmentId).then(blob => {
										if (blob) window.open(URL.createObjectURL(blob), '_blank')
									})
								}
							}}
						>
							Otwórz
						</Link>
					) : (
						'-'
					),
			},
		],
		[],
	)

	const entriesTableData = useMemo(() => entries ?? [], [entries])

	const tableInstance = useTable(
		{
			data: entriesTableData,
			columns: columns,
		},
		useRowSelect,
	)

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={new Bill(bill)}
			innerRef={formik}
			validationSchema={Bill.validationSchema}
		>
			{({ values, setFieldValue, errors }) => (
				<Form>
					<Tile
						grid
						{...rest}
						titleElement={undefined}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										bill?._id
											? navigate(
												patientId
													? `/patients/${patientId}/bills/`
													: '/bills/' + bill._id,
											)
											: navigate(
												patientId ? `/patients/${patientId}/bills` : '/bills',
											)
									}
								/>
							</>
						}
					>
						<AddEntryModal
							selectedEntry={selectedEntry}
							entries={entries}
							isOpen={addEntryDisclosure.isOpen}
							onClose={(e, f) => {
								if (e) {
									if (f) e.fileToUpload = f
									if (selectedEntry) {
										const newEntries = [...entries]
										const idx = newEntries.findIndex(
											e => e.no === selectedEntry.no,
										)
										newEntries[idx] = e
										setEntries(newEntries)
									} else {
										setEntries([...entries, e])
									}
								}
								addEntryDisclosure.onClose()
							}}
						/>
						<GridItem colSpan={{ base: 2, xl: 1 }}>
							<PatientModalList
								isOpen={showAllPatientsDisclosure.isOpen}
								onClose={patient => {
									if (patient) changePatient(patient)
									showAllPatientsDisclosure.onClose()
								}}
							/>
							<FormControl isInvalid={Boolean(errors.patient)}>
								<Dropdown
									displayAccessor="fullName"
									items={patients}
									selectedItem={selectedPatient}
									setSelectedItem={changePatient}
									placeholder="Wybierz pacjenta"
									label="Pacjent"
									render={(patient: Patient) => patient.fullName}
									numberOfResults={6}
									onShowAllClick={showAllPatientsDisclosure.onOpen}
									name="patient"
									disabled={Boolean(patientId) || Boolean(bill?.patient)}
								/>
								{errors.patient && (
									<FormErrorMessage>{errors.patient}</FormErrorMessage>
								)}
							</FormControl>
							<InputField
								name="dateOfIssue"
								placeholder="Data wystawienia"
								label="Data wystawienia"
								type="date"
								onChange={e => {
									setFieldValue('dateOfIssue', e.target.value)
									setFieldValue(
										'paymentDate',
										formatDate(
											new Date(
												new Date(e.target.value).setMonth(
													new Date(e.target.value).getMonth() + 1,
												),
											).toISOString(),
											'date',
										),
									)
								}}
							/>
							<InputField
								name="paymentDate"
								placeholder="Data płatności"
								label="Data płatności"
								type="date"
							/>
							<SelectField
								placeholder="Rok rozliczeniowy"
								name="billNumberYear"
								label="Rok rozliczeniowy"
								options={Array.from(
									{ length: Math.abs(2023 - new Date().getFullYear()) + 2 },
									(_, i) => i + 2023,
								).map(x => ({ label: x.toString(), value: x }))}
							/>
							<SelectField
								placeholder="Miesiąc rozliczeniowy"
								name="billNumberMonth"
								label="Miesiąc rozliczeniowy"
								options={[
									{ value: 0, label: 'Styczeń' },
									{ value: 1, label: 'Luty' },
									{ value: 2, label: 'Marzec' },
									{ value: 3, label: 'Kwiecień' },
									{ value: 4, label: 'Maj' },
									{ value: 5, label: 'Czerwiec' },
									{ value: 6, label: 'Lipiec' },
									{ value: 7, label: 'Sierpień' },
									{ value: 8, label: 'Wrzesień' },
									{ value: 9, label: 'Październik' },
									{ value: 10, label: 'Listopad' },
									{ value: 11, label: 'Grudzień' },
								]}
							/>
						</GridItem>
						<GridItem colSpan={{ base: 2, xl: 1 }}>
							<Table
								tableInstance={tableInstance}
								buttons={[
									{
										type: 'add',
										action: () => {
											setSelectedEntry(undefined)
											addEntryDisclosure.onOpen()
										},
									},
								]}
								menuOptions={{
									singleSelection: [
										{
											label: 'Usuń',
											onClick: () => {
												setEntries(
													entries
														.filter(
															e =>
																e.no !==
																tableInstance.selectedFlatRows[0]?.original.no,
														)
														.map((e, idx) => ({ ...e, no: idx + 1 })),
												)
											},
										},
									],
									multipleSelection: [
										{
											label: 'Usuń wiele',
											onClick: () => {
												setEntries(
													entries
														.filter(
															e =>
																!tableInstance.selectedFlatRows
																	.map(r => r.original.no)
																	.includes(e.no),
														)
														.map((e, idx) => ({ ...e, no: idx + 1 })),
												)
											},
										},
									],
								}}
							/>
						</GridItem>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
