import { Select } from '@chakra-ui/react'
import {
	formatDate,
	InputField,
	InputSpacingWrapper,
	ModalDialog,
} from '@kevea/react-components'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useRef, useState } from 'react'
import * as yup from 'yup'

type Props = {
	isOpen: boolean
	onClose: (dateRange?: [Date, Date]) => void
	header: string
	headingText: string
}

type FormValues = {
	startDate: string
	endDate: string
}

const validationSchema = yup.object({
	startDate: yup
		.date()
		.required('Data od jest wymagana')
		.max(new Date(), 'Data od nie może być w przyszłości'),
	endDate: yup
		.date()
		.required('Data do jest wymagana')
		.max(new Date(), 'Data do nie może być w przyszłości')
		.min(yup.ref('startDate'), 'Data do nie może być wcześniejsza niż data od'),
})

const initDate = (type: 'start' | 'end') => {
	const d = new Date()
	if (type === 'start') d.setMonth(d.getMonth() - 3)
	return formatDate(new Date(d).toISOString(), 'date')
}

export const PrintModal = ({ isOpen, onClose, header, headingText }: Props) => {
	const [customRange, setCustomRange] = useState(false)
	const formik = useRef<FormikProps<FormValues>>(null)
	const handleSubmit = (values: FormValues) => {
		if (!customRange) {
			values.endDate = initDate('end')
		}
		const newStart = new Date(values.startDate)
		const newEnd = new Date(values.endDate)
		newStart.setHours(0, 0, 0, 0)
		newEnd.setHours(23, 59, 59, 999)
		onClose([newStart, newEnd])
	}

	const handlePresetChange = (value: string, f: FormikProps<FormValues>) => {
		let startDate = new Date(f.values.startDate)
		let endDate = new Date(f.values.endDate)
		if (value === 'other') {
			setCustomRange(true)
		} else {
			setCustomRange(false)
			startDate = new Date()
			endDate = new Date(initDate('end'))
		}
		if (value === '3m') {
			startDate.setMonth(startDate.getMonth() - 3)
		}
		if (value === '1m') {
			startDate.setMonth(startDate.getMonth() - 1)
		}
		if (value === '1w') {
			startDate.setDate(startDate.getDate() - 7)
		}
		if (value === '1d') {
			startDate.setDate(startDate.getDate() - 1)
		}
		f.setFieldValue('startDate', formatDate(startDate.toISOString(), 'date'))
		f.setFieldValue('endDate', formatDate(endDate.toISOString(), 'date'))
	}

	return (
		<ModalDialog
			header={header}
			isOpen={isOpen}
			onClose={() => onClose()}
			onSave={() => formik.current?.handleSubmit()}
			confirmButtonLabel="Drukuj"
		>
			<Formik
				innerRef={formik}
				initialValues={{
					startDate: initDate('start'),
					endDate: initDate('end'),
				}}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{helpers => (
					<Form>
						<InputSpacingWrapper>
							<h3>{headingText}</h3>
							<Select
								onChange={e => handlePresetChange(e.target.value, helpers)}
							>
								<option value="3m">Ostatnie 3 miesiące</option>
								<option value="1m">Ostatni miesiąc</option>
								<option value="1w">Ostatni tydzień</option>
								<option value="1d">Od wczoraj</option>
								<option value="other">Inny</option>
							</Select>
							{customRange && (
								<>
									<InputField name="startDate" label="Data od" type="date" />
									<InputField name="endDate" label="Data do" type="date" />
								</>
							)}
						</InputSpacingWrapper>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
