import { formatDate } from '@kevea/react-components'
import { TableMedicalIncident } from 'models/medicalIncident'
import { Patient } from 'models/patient'
import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { MedicalIncidentContext } from '../MedicalIncident'

export const AttachmentsWrapper = () => {
	const { medicalIncident, breadcrumbs, refetch } = useOutletContext<MedicalIncidentContext>()

	return (
		<Attachments
			attachmentType="medicalIncidents"
			idParamName="incidentId"
			breadcrumbs={breadcrumbs}
			onAction={refetch}
			header={`${formatDate(medicalIncident?.date ?? '', 'localeDateString')} - ${new TableMedicalIncident(medicalIncident).addedBy} - Lista załączników`}
			parentPath="patients"
			parentId={medicalIncident?.patient?._id}
		/>
	)
}
