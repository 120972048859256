export const specjalizacjeLekarskie = [
	{ value: "alergolog" },
	{ value: "anestezjolog i specjalista intensywnej terapii" },
	{ value: "angiolog" },
	{ value: "audiolog i foniatra" },
	{ value: "balneolog i specjalista medycyny fizykalnej" },
	{ value: "chirurg dziecięcy" },
	{ value: "chirurg klatki piersiowej" },
	{ value: "chirurg naczyniowy" },
	{ value: "chirurg ogólny" },
	{ value: "chirurg onkologiczny" },
	{ value: "chirurg plastyczny" },
	{ value: "chirurg szczękowo-twarzowy" },
	{ value: "pulmonolog" },
	{ value: "pulmonolog dziecięcy" },
	{ value: "internista" },
	{ value: "specjalista chorób zakaźnych" },
	{ value: "dermatolog i wenerolog" },
	{ value: "diabetolog" },
	{ value: "diagnosta laboratoryjny" },
	{ value: "endokrynolog" },
	{ value: "endokrynolog ginekologiczny i specjalista rozrodczości" },
	{ value: "endokrynolog i diabetolog dziecięcy" },
	{ value: "epidemiolog" },
	{ value: "farmakolog kliniczny" },
	{ value: "gastroenterolog" },
	{ value: "gastroenterolog dziecięcy" },
	{ value: "genetyk kliniczny" },
	{ value: "geriatra" },
	{ value: "ginekolog onkologiczny" },
	{ value: "hematolog" },
	{ value: "hipertensjolog" },
	{ value: "immunolog kliniczny" },
	{ value: "specjalista intensywnej terapii" },
	{ value: "kardiochirurg" },
	{ value: "kardiolog" },
	{ value: "kardiolog dziecięcy" },
	{ value: "specjalista medycyny lotniczej" },
	{ value: "specjalista medycyny morskiej i tropikalnej" },
	{ value: "specjalista medycyny nuklearnej" },
	{ value: "specjalista medycyny paliatywnej" },
	{ value: "specjalista medycyny pracy" },
	{ value: "specjalista medycyny ratunkowej" },
	{ value: "rodzinny" },
	{ value: "specjalista medycyny sądowej" },
	{ value: "specjalista medycyny sportowej" },
	{ value: "mikrobiolog kliniczny" },
	{ value: "nefrolog" },
	{ value: "nefrolog dziecięcy" },
	{ value: "neonatolog" },
	{ value: "neurochirurg" },
	{ value: "neurolog" },
	{ value: "neurolog dziecięcy" },
	{ value: "neuropatolog" },
	{ value: "okulista" },
	{ value: "onkolog i hematolog dziecięcy" },
	{ value: "onkolog kliniczny" },
	{ value: "ortopeda i traumatolog narządu ruchu" },
	{ value: "otorynolaryngolog" },
	{ value: "otorynolaryngolog dziecięcy" },
	{ value: "patomorfolog" },
	{ value: "pediatra" },
	{ value: "specjalista pediatrii metabolicznej" },
	{ value: "perinatolog" },
	{ value: "położnik i ginekolog" },
	{ value: "psychiatra" },
	{ value: "psychiatra dzieci i młodzieży" },
	{ value: "radiolog i specjalista diagnostyki obrazowej" },
	{ value: "radioterapeuta onkologiczny" },
	{ value: "specjalista rehabilitacji medycznej" },
	{ value: "reumatolog" },
	{ value: "seksuolog" },
	{ value: "toksykolog kliniczny" },
	{ value: "transfuzjolog kliniczny" },
	{ value: "transplantolog kliniczny" },
	{ value: "urolog" },
	{ value: "urolog dziecięcy" },
	{ value: "specjalista zdrowia publicznego" }
];

export const specjalizacjeLekarskoDentystyczne = [
	{ value: "chirurg stomatologiczny" },
	{ value: "chirurg szczękowo-twarzowy" },
	{ value: "epidemiolog" },
	{ value: "ortodonta" },
	{ value: "periodontolog" },
	{ value: "protetyk stomatologiczny" },
	{ value: "stomatolog dziecięcy" },
	{ value: "stomatolog zachowawczy i endodonta" },
	{ value: "specjalista zdrowia publicznego" }
];
