import { FaNotesMedical } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { MedicalIncident } from './MedicalIncident'
import { MedicalIncidentList } from './MedicalIncidentList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const medicalIncidentRoutes: RouteObject[] = [
	{
		path: '/patients/:id/medicalIncidents',
		element: (
			<MedicalIncidentList />
		),
		breadcrumb: 'Zdarzenia Medyczne',
	},
	{
		path: '/patients/:id/medicalIncidents/:incidentId',
		element: (
			<MedicalIncident />
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Zdarzenie medyczne',
				breadcrumbIcon: <FaNotesMedical />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja zdarzenia medycznego',
				breadcrumbIcon: <FaNotesMedical />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaNotesMedical />,
			},
		],
	},
	{
		path: '/patients/:id/medicalIncidents/new',
		element: (
			<MedicalIncident />
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowe zdarzenie medyczne',
				breadcrumbIcon: <FaNotesMedical />,
			},
		],
	},
]

export default medicalIncidentRoutes
