import { Patient } from 'models/patient'
import { Stock } from 'models/stock'
import { PrintOptions } from 'pages/patients/PrintListModal'
import { getBaseInstancePath, printConfig, Service } from 'services'
import { getFullDayDates } from 'utils/time'
import http from './axios'

const path = '/patients'

export class PatientService extends Service<Patient>({ path }) {
	static async getMedicineStock(id: string): Promise<Stock[]> {
		return http
			.get<Stock[]>(`${getBaseInstancePath(path)}/${id}/medicineStock`)
			.then(res => res.data)
	}
	static async infoCard(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/infocard`, printConfig)
			.then(res => res.data)
	}
	static async printTag(id: string, timeOfDay: string): Promise<Blob> {
		return http
			.post<Blob>(
				`${getBaseInstancePath(path)}/${id}/tag`,
				{ timeOfDay },
				printConfig,
			)
			.then(res => res.data)
	}
	static async recordSheet(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/recordsheet`, printConfig)
			.then(res => res.data)
	}
	static async printMedicines(id: string): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(path)}/${id}/medicinesPrint`,
				printConfig,
			)
			.then(res => res.data)
	}
	static async printMeasurements(
		id: string,
		startDate: Date,
		endDate: Date,
		types?: number[],
	): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					path,
				)}/${id}/measurementsPrint?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}` +
				(types ? `&types=${types.join(',')}` : ''),
				printConfig,
			)
			.then(res => res.data)
	}

	static async printTasks(id: string, date: string, onlyCompleted: boolean): Promise<Blob> {
		const [fromDate, toDate] = getFullDayDates(new Date(date))
		return http
			.get<Blob>(
				`${getBaseInstancePath(path)}/${id}/tasks/print?fromDate=${fromDate}&toDate=${toDate}&onlyCompleted=${onlyCompleted}`,
				printConfig,
			)
			.then(res => res.data)
	}

	static async printNotes(
		id: string,
		startDate: Date,
		endDate: Date,
	): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					path,
				)}/${id}/notesPrint?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
				printConfig,
			)
			.then(res => res.data)
	}
	static async printList(options: PrintOptions): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(path)}/print/list?status=${options.status
				}&columns=${options.columns}`,
				printConfig,
			)
			.then(res => res.data)
	}
}
