import { formatDate } from '@kevea/react-components'
import { MedicalIncident } from 'models/medicalIncident'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/medicalIncidents'
const objectPath = '/patients'

export class MedicalIncidentService extends Service<MedicalIncident>({
	objectPath,
	path,
	objectService: true,
}) {
	static async print(id: string, patientId: string): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(objectPath)}/${patientId}${path}/${id}/print`,
				printConfig,
			)
			.then(res => res.data)
	}
	static async printRange(patientId: string, from: Date, to: Date): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(objectPath)}/${patientId}${path}/printRange?from=${formatDate(from.toISOString(), 'date')}&to=${formatDate(to.toISOString(), 'date')}`,
				printConfig,
			)
			.then(res => res.data)
	}
}
