import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { useFloating, autoUpdate, flip, size, useRole, useDismiss, useListNavigation, useFocus, useInteractions, FloatingPortal, FloatingFocusManager, useId } from '@floating-ui/react'
import { Box, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { FaTimes } from "react-icons/fa"
import { useField, useFormikContext } from "formik"
import { Label } from "@kevea/react-components"

export type ComboBoxItem<T> = T & { value: string }

type Props<T> = {
	data: ComboBoxItem<T>[]
	render: (item: ComboBoxItem<T>) => React.ReactNode
	onSelect: (item: ComboBoxItem<T> | null) => void
	value: string
	placeholder: string
}

const MAX_HEIGHT = 300;

export function ComboBox<T>({ data, onSelect, render, value, placeholder }: Props<T>) {
	const [query, setQuery] = useState<string>("")
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [activeIndex, setActiveIndex] = useState<number | null>(null)

	useEffect(() => {
		setQuery(value)
	}, [value])

	useEffect(() => {
		if (value && data) {
			if (data.findIndex(x => x.value === value) === -1) {
				clearValue()
			}
		}
	}, [data])

	const listRef = useRef<Array<HTMLElement | null>>([]);

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;
		setQuery(value);
		setIsOpen(true);
		setActiveIndex(0);
	}

	const { refs, floatingStyles, context } = useFloating({
		whileElementsMounted: autoUpdate,
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [
			flip({ padding: 10 }),
			size({
				apply({ rects, availableHeight, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width}px`,
						maxHeight: availableHeight > MAX_HEIGHT ? `${MAX_HEIGHT}px` : `${availableHeight}px`,
					});
				},
				padding: 10,
			}),
		]
	})

	const role = useRole(context, { role: "listbox" });
	const dismiss = useDismiss(context, {
		escapeKey: false
	});
	const focus = useFocus(context)
	const listNav = useListNavigation(context, {
		listRef,
		activeIndex,
		onNavigate: setActiveIndex,
		virtual: true,
		loop: true,
	});

	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
		[role, dismiss, listNav, focus]
	);

	const filteredData = useMemo(() => {
		if (query === "") return data
		return data.filter(x => x.value.toLowerCase().includes(query.toLowerCase()))
	}, [query, data])

	const clearValue = () => {
		setQuery('')
		onSelect(null)
		setIsOpen(false)
	}


	return (
		<>
			<InputGroup>
				<Input placeholder={placeholder}
					{...getReferenceProps({
						ref: refs.setReference,
						onChange,
						value: query,
						"aria-autocomplete": "list",
						onKeyDown(event) {
							if (
								event.key === "Enter" &&
								activeIndex != null &&
								filteredData[activeIndex]
							) {
								setQuery(filteredData[activeIndex].value);
								setActiveIndex(null);
								onSelect(filteredData[activeIndex])
								setIsOpen(false);
							}
						},
					})}
					onBlur={e => {
						if (e.relatedTarget?.id && refs.floating.current?.id && e.relatedTarget.id === refs.floating.current.id) return
						if (!value) {
							clearValue()
						} else {
							setQuery(value)
						}

					}}
				/>
				{value &&
					<InputRightElement cursor={'pointer'} onClick={clearValue} ><FaTimes /></InputRightElement>
				}
			</InputGroup>
			{isOpen && <FloatingPortal>
				<FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
					<Box {...getFloatingProps({
						ref: refs.setFloating,
						style: {
							...floatingStyles,
							background: "#F7FAFC",
							border: "1px solid #E2E8F0",
							borderRadius: "0.375rem",
							color: "black",
							overflowY: "auto",
							boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 0px 4px 0px"
						},
					})}>
						{filteredData.map((item, index) => (
							<Item
								{...getItemProps({
									key: item.value,
									ref(node) {
										listRef.current[index] = node;
									},
									onClick() {
										setQuery(item.value);
										onSelect(item)
										setIsOpen(false);

									},
								})}
								active={activeIndex === index}
							>
								{render(item)}
							</Item>
						))}
					</Box>
				</FloatingFocusManager>
			</FloatingPortal>}
		</>
	)

}


interface ItemProps {
	children: React.ReactNode;
	active: boolean;
}

const Item = forwardRef<
	HTMLDivElement,
	ItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, ...rest }, ref) => {
	const id = useId();
	return (
		<div
			ref={ref}
			role="option"
			id={id}
			aria-selected={active}
			{...rest}
			style={{
				background: active ? "#E2E8F0" : "none",
				padding: 4,
				cursor: "pointer",
				...rest.style,
			}}
		>
			{children}
		</div>
	);
});


type ComboBoxFieldProps<T> = {
	placeholder: string
	label: string
	name: string
	data: ComboBoxItem<T>[]
	render: (item: ComboBoxItem<T>) => React.ReactNode
}

export function ComboBoxField<T>({ placeholder, name, data, render, label }: ComboBoxFieldProps<T>) {
	const [field, meta, helper] = useField(name)
	const formik = useFormikContext()
	const [shouldDisplayError, setShouldDisplayError] = useState(false)

	useEffect(() => {
		if (formik.submitCount > 0) {
			setShouldDisplayError(true)
		}
	}, [formik.submitCount])

	return <FormControl isInvalid={shouldDisplayError && Boolean(meta.error)}>
		<Label>{label}</Label>
		<ComboBox data={data} placeholder={placeholder} onSelect={item => helper.setValue(item?.value ?? '')} render={render} value={field.value ?? ''} />
		<FormErrorMessage>
			{meta.error}
		</FormErrorMessage>
	</FormControl>
}