import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	BreadcrumbData,
	DateFilter,
	formatDate,
	Link,
	SelectFilter,
	Table,
	Tile,
	useErrorToast,
} from '@kevea/react-components'
import { HeaderDatePicker } from 'components/HeaderDatePicker'
import { Note, TableNote } from 'models/note'
import { Patient } from 'models/patient'
import { RhmShiftSettings } from 'models/service'
import { polishPlurals } from 'pages/tasks/Tasks'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { NoteService } from 'services/note'
import { PatientContext } from '../patients/Patient'
import { NoteModal } from './NotesModal'
import { PatientService } from 'services/patient'
import { PrintModal } from 'components/PrintModal'

export type NotesProps = {
	patient?: Patient
	breadcrumbs?: BreadcrumbData<string>[]
	selectedDate: string
	setSelectedDate: (date: string) => void
	selectedShift?: RhmShiftSettings
	setSelectedShift?: (shift: RhmShiftSettings) => void
	shiftSettings?: RhmShiftSettings[]
}

export const Notes = ({
	patient,
	breadcrumbs,
	selectedDate,
	setSelectedDate,
	selectedShift,
	setSelectedShift,
	shiftSettings,
}: NotesProps) => {
	const [isRemoving, setIsRemoving] = useState(false)
	const [selectedNote, setSelectedNote] = useState<Note | undefined>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()
	const printDisclosure = useDisclosure()
	const [loading, setLoading] = useState(false)
	const error = useErrorToast()

	const {
		data: notes,
		isLoading,
		isRefetching,
		refetch: refetchNotes,
	} = useQuery('fetchNotes', () =>
		Note.fetchNotes(patient, selectedDate, selectedShift),
	)

	useEffect(() => {
		refetchNotes()
	}, [selectedDate, selectedShift])

	const data = useMemo(
		() =>
			notes
				?.sort(function (a: Note, b: Note) {
					return new Date(b.date).getTime() - new Date(a.date).getTime()
				})
				.map(note => new TableNote(note)) ?? [],
		[notes],
	)

	const columns = useMemo<Column<TableNote>[]>(
		() => [
			{
				Header: 'Typ notatki',
				accessor: 'name',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedNote(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Pacjent',
				accessor: 'patient',
			},
			{
				Header: 'Opis',
				accessor: 'description',
			},
			{
				Header: 'Data',
				accessor: 'date',
			},
			{
				Header: 'Dodane przez',
				accessor: 'addedBy',
			}
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
			initialState: {
				hiddenColumns: [patient ? 'patient' : ''],
			},
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)


	const handlePrintDisclosureOnClose = (dateRange?: [Date, Date]) => {
		if (dateRange) {
			setLoading(true)
			printDisclosure.onClose()
			PatientService.printNotes(patient._id, dateRange[0], dateRange[1])
				.then(blob => {
					if (blob) window.open(URL.createObjectURL(blob), '_blank')
					setLoading(false)
				})
				.catch(e => {
					error({ description: 'Wystąpił błąd podczas generowania wydruku' })
					setLoading(false)
				})
		} else {
			printDisclosure.onClose()
		}
	}

	return (
		<Tile
			breadcrumbs={breadcrumbs}
			header={`Notatki ${selectedShift ? formatDate(selectedDate, 'localeDateString') : ''
				} - ${patient
					? Patient.getFullName(patient)
					: selectedShift
						? 'Zmiana ' + selectedShift.name
						: ''
				}`}
			headerButtons={
				<>
					{!patient && (
						<HeaderDatePicker
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							selectedShift={selectedShift}
							setSelectedShift={setSelectedShift}
							shiftSettings={shiftSettings}
						/>
					)}
				</>
			}
		>

			{printDisclosure.isOpen && (
				<PrintModal
					isOpen={printDisclosure.isOpen}
					onClose={handlePrintDisclosureOnClose}
					headingText="Wybierz okres notatek do wydruku"
					header='Wydruk notatek'
				/>
			)}
			<NoteModal
				date={selectedDate}
				isOpen={addDisclosure.isOpen}
				selectedNote={selectedNote}
				onClose={() => {
					setSelectedNote(undefined)
					addDisclosure.onClose()
					refetchNotes()
				}}
				patient={patient}
			/>
			<AlertDialog
				header="Usuń notatkę"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (tableInstance.selectedFlatRows.length > 0) {
						setIsRemoving(true)
						const promises: Promise<any>[] = []
						for (let row of tableInstance.selectedFlatRows) {
							if (
								row.original.original?._id &&
								row.original.original.patient._id
							) {
								promises.push(
									NoteService.delete(
										row.original.original._id,
										row.original.original.patient._id,
									),
								)
							}
						}
						Promise.allSettled(promises).then(res => {
							setIsRemoving(false)
							setSelectedNote(undefined)
							removeDisclosure.onClose()
							refetchNotes()
						})
					}
				}}
			>
				{tableInstance.selectedFlatRows.length === 1 ? (
					<>
						Czy chcesz usunąć notatkę z dnia{' '}
						<strong>
							{selectedNote?.date &&
								formatDate(selectedNote.date, 'localeString')}
						</strong>{' '}
						dla{' '}
						<strong>
							{selectedNote?.patient &&
								Patient.getFullName(selectedNote.patient)}
						</strong>
						?
					</>
				) : (
					<>
						Czy chcesz usunąć{' '}
						<strong>{tableInstance.selectedFlatRows.length}</strong>{' '}
						{polishPlurals(
							'notatkę',
							'notatki',
							'notetek',
							tableInstance.selectedFlatRows.length,
						)}
						?
					</>
				)}
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading || isRefetching || loading}
				buttons={[
					{
						type: 'add',
						action: () => {
							addDisclosure.onOpen()
						},
					}, {
						type: 'print',
						action: () => {
							printDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: (notes: TableNote[]) => {
								setSelectedNote(notes[0]?.original)
								removeDisclosure.onOpen()
							},
						},
					],
					multipleSelection: [
						{
							label: 'Usuń',
							onClick: (notes: TableNote[]) => {
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
